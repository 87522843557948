$small-width: 576px;
$medium-width: 768px;
$large-width: 992px;
$extraLarge-width: 1200px;

@mixin extraSmallOnly {
    @media(max-width: #{$small-width - 1px}){
        @content;
    }
}

@mixin smallAndUp{
    @media (min-width: #{$small-width}){
        @content;
    }
}

@mixin smallToMedium{
    @media (min-width: #{$small-width}) and (max-width: 767px) {
        @content;
    }
}

@mixin smallAndLess {
    @media (max-width: 767px){
        @content;
    }
}

@mixin mediumAndUp {
  @media (min-width: #{$medium-width}){
        @content;
  }
}

@mixin mediumToLarge {
    @media (min-width: #{$medium-width}) and (max-width: 991px) {
      @content;
    }
}

@mixin mediumAndLess {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin largeAndUp {
    @media (min-width: #{$large-width}) {
        @content;
    }
}

@mixin largeAndLess {
    @media (max-width: 1199px){
        @content;
    }
}

@mixin extraLarge {
    @media (min-width: #{$extraLarge-width}) {
      @content;
    }
}