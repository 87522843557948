.home__body{
    padding: 0;
    .home__body__item{
        padding:2rem 0;
        height: calc(100vh - 88px);
    }
    .home__body__1{
        img{
            width:100%;
            background-color: #f4f2f5;
        }
    }
    .home__body__2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text{
            font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 4rem;
            line-height: 4.5rem;
            padding: 1rem;
        }
        .link{
            color: $colors-black--light;
            text-decoration: none;
            font-size: 1.25rem;
            &:hover{
                color: $colors-black;
            }
        }
    }
}