.services{
    margin-top: 8rem;
    .services__item{
        margin-bottom: 8rem;
        .image{
            width: 100%;
            max-width: 40rem;
            height: 20rem;
        }
    }
}

