.navigation-menu__nav{
    padding: 0.5rem 0rem;

    .navigation-menu__home-link-wrapper{
        font-size: 3rem;
        font-family: monospace;
        font-weight: bolder;
        .navigation-menu__home-link{
            display: flex;
            align-items: center;
            padding: 0;
            .navigation-menu__home-link__logo{
                width: 48.6px;
                height: 57.6px;
                margin-right: 0.5rem;
                img{
                    width: 100%;
                    height: 100%;
                    vertical-align: initial;
                }
            }
            .navigation-menu__home-link__1{
                color: black;
                background-color: white;
            }
            .navigation-menu__home-link__2{
                color: white;
                background-color: black;
            }
        }
    }

    .navigation-menu__page-links{
        ul {
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @include mediumAndUp{
                grid-template-columns: repeat(4, 1fr);
            }
        }
        li {
            padding: 0 2rem;
        }
        a {
            font-size: 1.125rem;
            padding: 0rem;
            font-weight: 600;
            &.active{
                &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 0.125rem;
                    background: $colors-black;
                    transition: width .3s;
                    margin-top: 1rem;
                }
            }
        }
    }
}

.navigation-menu-desktop{
    &.non-home-page{
        // background: white;
        width: 100%;
        display: inline-block;
        position: relative;
        >::before{
            content: "";
            box-shadow: inset 0px 0px 1rem -0.5rem $colors-black;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: scaleX(0);
            transform-origin: left;
            animation-name: leftToRight;
            animation-delay: 0.25s;
            animation-duration: 0.75s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-out;
            z-index:-1;
        }
    }
}

