.animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 }

 .animatedafter1{
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-duration:3s;
    animation-delay:1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 }

 .animated5{
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

 .animated10{
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 }

 .fadeIn{
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
 }

 .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    } 

 .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.fadeInDownMultiple {
    -webkit-animation-name: fadeInDownMultiple;
    animation-name: fadeInDownMultiple;
}

.fadeInUp {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInUp;
}

.growLeft {
    -webkit-animation-name: growLeft;
    animation-name: growLeft;
}

.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-name: hinge;
    animation-name: hinge;
  }
  .rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
  }

 @-webkit-keyframes fadeIn {
    0% {
       opacity: 0;
    //    -webkit-transform: translateX(0px);
    }
    100% {
       opacity: 1;
    //    -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeIn {
    0% {
       opacity: 0;
    //    transform: translateX(0px);
    }
    100% {
       opacity: 1;
    //    transform: translateX(0);
    }
 }
 
 @-webkit-keyframes fadeInLeft {
    0% {
       opacity: 0;
       -webkit-transform: translateX(-100px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }
 
 @-webkit-keyframes fadeInRight {
    0% {
       opacity: 0;
       -webkit-transform: translateX(100px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }

 @-webkit-keyframes fadeInDown {
    0% {
       opacity: 0;
       -webkit-transform: translateY(-100px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }

 @-webkit-keyframes fadeInDownMultiple {
    0% {
       opacity: 0;
       -webkit-transform: translateY(-300px);
    }
    50% {
        opacity: 1;
        -webkit-transform: translateY(60px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeInDownMultiple {
    0% {
       opacity: 0;
       transform: translateY(-300px);
    }
    50% {
        opacity: 1;
        -webkit-transform: translateY(60px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }

 @-webkit-keyframes fadeInUp {
    0% {
       opacity: 0;
       -webkit-transform: translateY(100px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeInUp {
    0% {
       opacity: 0;
       transform: translateY(100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }
 
 @-webkit-keyframes growLeft {
    0% {
       opacity: 0;
       -webkit-transform: scaleX(0);
    }
    100% {
       opacity: 1;
       -webkit-transform: scaleX(1);
    }
 }

 @keyframes growLeft {
    from {
        opacity:0;
        transform: scaleX(0);
    }
    to {
        opacity:1;
        transform: scaleX(1);
    }
  }

  @-webkit-keyframes hinge {
    0% {
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  
    20%,
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 80deg);
      transform: rotate3d(0, 0, 1, 80deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  
    40%,
    80% {
      -webkit-transform: rotate3d(0, 0, 1, 60deg);
      transform: rotate3d(0, 0, 1, 60deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translate3d(0, 400px, 0);
      transform: translate3d(0, 400px, 0);
      opacity: 0;
    }
  
    to {
      -webkit-transform: translate3d(0, 000px, 0);
      transform: translate3d(0, 000px, 0);
      opacity: 0;
    }
  }
  
  @keyframes hinge {
    0% {
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  
    20%,
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 80deg);
      transform: rotate3d(0, 0, 1, 80deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  
    40%,80% {
      -webkit-transform: rotate3d(0, 0, 1, 60deg);
      transform: rotate3d(0, 0, 1, 60deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  
    90% {
      -webkit-transform: rotate3d(0, 0, 1, 15deg);
      transform:  rotate3d(0, 0, 1, 45deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    100% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform:  rotate3d(0, 0, 1, 15deg);
        -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  
    to {
      -webkit-transform:rotate3d(0, 0, 1, 15deg);
      transform: rotate3d(0, 0, 1, 0deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes rubberBand {
      from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
    
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
    
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
    
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
    
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
    
      to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    
    @keyframes rubberBand {
      from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
    
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
    
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
    
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
    
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
    
      to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    
 
    
    .hvr-underline-from-left:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0;
      background: #2098D1;
      height: 4
  px
  ;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
  }
 
