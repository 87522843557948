.contact__container{
    .contact{
        display: flex;
        .contact__item{
            max-width: 20rem;
            height: 9.5rem;
            border-radius: 1rem;
            box-shadow: $box-shadow__light;
            margin: 2rem auto;
            padding: 2rem 1rem;
            overflow: auto;
            .text{
                p{
                    font-size: 1.2rem;
                }
            }
        }
    }
}