.footer{
    text-align: left;

    .footer__details{
        background-color: black;
        color: white;
        padding: 0rem;  

        .footer__details__child{
            .item{
                padding: 3rem 1rem;
                &.about{
                    .body{
                        margin-top: 3rem;
                    }
                }
                .header{
                    font-size: 18px;
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                }
            }

            .navigation{
                ul{
                    list-style: none;
                    padding-left: 0;
                    li{
                        margin: 0.5rem 0rem;
                        a{
                            padding: 0rem;
                            color: white;
                        }
                    }
                }
            }

            .corporate-enquiry{
                .body>div{
                    margin-bottom: 1rem;
                }
            }

        }
    }

    .footer__copyright{
        background-color: white;
        color: black;
        padding: 1rem 0.25rem;
    }
}