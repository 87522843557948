@import '../../styles/mixins';

@import 'variables';

@import 'home';
@import 'about';
@import 'services';
@import 'careers';
@import 'contact';
@import 'navigation-menu';
@import 'footer';

.app-container {
  .app-content {
    position: relative;
    z-index:2;
    background: white;
    padding-bottom: 10rem;
    min-height: 100vh;
  }
  .app-footer {
    z-index: 1;
    width: 100%;
    @include largeAndUp{
      position: sticky;
      left: 0;
      bottom: 0;
    }
  }
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include smallAndUp{
      width: 530px;
    }
    @include mediumAndUp{
      width: 720px;
    }
    @include largeAndUp{
      width: 960px;
    }
    @include extraLarge{
      width: 1140px;
    }
}

a {
  color: $colors-black--light;
  text-decoration: none;
  padding: 1rem;
  @include mediumAndLess{
    color: $colors-black;
  }
  &:hover {
      color: $colors-black;
      transition: color .3s;
  }

  &.animated{
      position: relative;
      overflow: hidden;
      background: linear-gradient(to right, $colors-black, $colors-black 50%, $colors-black--light 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 100%;
      background-position: 100%;
      transition: background-position .3s ease;
      &:hover, &.active {
          background-position: 0 100%;
      }
  }

  &.animated-border-bottom{
      display: inline-block;
      margin-top: 1rem;
      &::after{
          content: '';
          display: block;
          width: 0;
          height: 0.125rem;
          background: $colors-black;
          transition: width .3s;
          margin-top: 1rem;
      }
      &:hover {
          &::after{
              width: 100%;
              transition: width .3s;
          }
      }
  }
}

.heading{
  color: $colors-black;
  font-size: 3rem;
  padding-top: 2rem;
  color: inherit;
}

.heading__description{
  font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-style: italic;
  font-size: 1.25rem;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 4.5rem;
}


.flex{
  display: flex;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.justify-content{
  justify-content: center;
}

.align-content{
  align-content: center;
}

.align-items{
  align-items: center;
}

.gap-1x{
  gap: 0.5rem;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.careers-item__description{
  padding-bottom: 1rem;
}

/* Keyframes */
@keyframes leftToRight {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes rightToLeft {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}