// colors

$colors-primary: rgba(0,0,0,0.8);
$colors-primary--light: rgba(0,0,0,0.4); 

$colors-black: rgba(0,0,0,0.8);
$colors-black--light: rgba(0,0,0,0.4); 
$colors-black--dark: #090909;

$box-shadow__light: 0 0 2rem 0.5rem rgb(0 0 0 / 5%);

/* Works on Firefox */
// * {
//     scrollbar-width: thin;
//     scrollbar-color: $colors-primary $colors-primary--light;
//   }
  
//   /* Works on Chrome, Edge, and Safari */
//   *::-webkit-scrollbar {
//     width: 0.5rem;
//   }
  
//   *::-webkit-scrollbar-track {
//     background: $colors-primary--light;
//   }
  
//   *::-webkit-scrollbar-thumb {
//     background-color: $colors-primary;
//     border-radius: 1rem;
//     border: 3px solid $colors-primary--light;
//   }
